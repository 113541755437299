import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { CampaignComponent } from './campaign/campaign.component';
import { AdminGuard } from './core/admin/admin.guard';
import { IsForArticleGuard } from './core/is-for-article/is-for-article.guard';
import { isLoggedInWithoutRedirect } from './core/is-logged-in-without-redirect/is-logged-in-without-redirect.guard';
import { isLoggedIn } from './core/is-logged-in/is-logged-in.guard';
import { RedirectToComponent } from './core/redirect-to/redirect-to.component';
import { AdvancedVideosComponent } from './dashboard/show-more-page/show-more-pages/advanced-videos.component';
import { BackVideosComponent } from './dashboard/show-more-page/show-more-pages/back-videos.component';
import { BeginnerVideosComponent } from './dashboard/show-more-page/show-more-pages/beginner-videos.component';
import { EstherVideosComponent } from './dashboard/show-more-page/show-more-pages/esther-videos.component';
import { EveningVideosComponent } from './dashboard/show-more-page/show-more-pages/evening-videos.component';
import { FavoritedVideosComponent } from './dashboard/show-more-page/show-more-pages/favorited-videos.component';
import { FitnessVideosComponent } from './dashboard/show-more-page/show-more-pages/fitness-videos.component';
import { LastViewedVideosComponent } from './dashboard/show-more-page/show-more-pages/last-viewed-videos.component';
import { LiveClassesVideosComponent } from './dashboard/show-more-page/show-more-pages/live-classes-videos.component';
import { MeditationVideosComponent } from './dashboard/show-more-page/show-more-pages/meditation-videos.component';
import { MorningVideosComponent } from './dashboard/show-more-page/show-more-pages/morning-videos.component';
import { NeckShoulderVideosComponent } from './dashboard/show-more-page/show-more-pages/neck-shoulder-videos.component';
import { NewVideosComponent } from './dashboard/show-more-page/show-more-pages/new-videos.component';
import { PopularProgramsComponent } from './dashboard/show-more-page/show-more-pages/popular-programs.component';
import { PopularVideosComponent } from './dashboard/show-more-page/show-more-pages/popular-videos.component';
import { ShortVideosComponent } from './dashboard/show-more-page/show-more-pages/short-videos.component';
import { StressVideosComponent } from './dashboard/show-more-page/show-more-pages/stress-videos.component';
import { WellRatedVideosComponent } from './dashboard/show-more-page/show-more-pages/well-rated-videos.component';
import { YinVideosComponent } from './dashboard/show-more-page/show-more-pages/yin-videos.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LiveClassesComponent } from './live-classes/live-classes.component';
import { LiveClassesState } from './live-classes/live-classes.state';
import { NotFoundPageComponent } from './shared/not-found-page/not-found-page.component';

const staticRedirects: Routes = [
  {
    path: 'events/yoga-reisen-retreats',
    redirectTo: '/magazin/yoga-reisen-retreats',
  },
  {
    path: 'events/yoga-workshops',
    redirectTo: '/live-klassen',
  },
  {
    path: 'events/yogalehrer-ausbildungen',
    redirectTo: '/redirect?to=https://academy.yogaeasy.de',
  },
  {
    path: 'events',
    redirectTo: '/',
  },
  {
    path: 'mein-konto',
    redirectTo: '/redirect?to=/mein-konto',
  },
];

const routes: Routes = [
  {
    path: '',
    canMatch: [isLoggedInWithoutRedirect],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: '',
    component: HomepageComponent,
  },
  // temporary manual routes until we have a 403 interceptor
  {
    path: 'meine-videos',
    pathMatch: 'full',
    redirectTo: '/user/sign_in',
  },
  {
    path: 'meine-programme',
    pathMatch: 'full',
    redirectTo: '/user/sign_in',
  },
  {
    path: 'meine-programme/:slug/teilnehmen',
    pathMatch: 'full',
    redirectTo: '/user/sign_in',
  },
  {
    path: 'homepage/videos',
    children: [
      {
        path: 'meine-favoriten',
        component: FavoritedVideosComponent,
        canActivate: [isLoggedIn],
      },
      {
        path: 'zuletzt-gesehen',
        component: LastViewedVideosComponent,
        canActivate: [isLoggedIn],
      },
      {
        path: 'neu',
        redirectTo: '/kategorie/videos/new',
      },
      {
        path: 'gut-bewertet',
        component: WellRatedVideosComponent,
        canActivate: [isLoggedIn],
      },
    ],
  },
  {
    path: 'kategorie',
    canActivateChild: [isLoggedIn],
    children: [
      {
        path: 'articles',
        children: [],
      },
      {
        path: 'podcasts',
        children: [],
      },
      {
        path: 'programs',
        children: [{ path: 'popular', component: PopularProgramsComponent }],
      },
      {
        path: 'teachers',
        children: [],
      },
      {
        path: 'videos',
        children: [
          { path: 'advanced', component: AdvancedVideosComponent },
          { path: 'back', component: BackVideosComponent },
          { path: 'beginner', component: BeginnerVideosComponent },
          { path: 'esther', component: EstherVideosComponent },
          { path: 'evening', component: EveningVideosComponent },
          { path: 'fitness', component: FitnessVideosComponent },
          {
            path: 'live-aufzeichnungen',
            component: LiveClassesVideosComponent,
          },
          { path: 'meditation', component: MeditationVideosComponent },
          { path: 'morning', component: MorningVideosComponent },
          { path: 'neckshoulder', component: NeckShoulderVideosComponent },
          { path: 'new', component: NewVideosComponent },
          { path: 'popular', component: PopularVideosComponent },
          { path: 'short', component: ShortVideosComponent },
          { path: 'stress', component: StressVideosComponent },
          { path: 'yin', component: YinVideosComponent },
        ],
      },
    ],
  },
  {
    path: 'redirect',
    component: RedirectToComponent,
  },
  {
    path: 'artikel',
    loadChildren: () =>
      import('./article/article.module').then(m => m.ArticleModule),
  },
  {
    path: 'campaign',
    component: CampaignComponent,
  },
  {
    path: 'free',
    loadChildren: () =>
      import('./free-trial/free-trial.module').then(m => m.FreeTrialModule),
  },
  {
    path: 'liste/newsletter',
    loadChildren: () =>
      import('./newsletter/newsletter.module').then(m => m.NewsletterModule),
  },
  {
    path: 'magazin',
    loadChildren: () =>
      import('./magazine/magazine.module').then(m => m.MagazineModule),
  },
  {
    path: 'meine-programme',
    loadChildren: () =>
      import('./article/article.module').then(m => m.ArticleModule),
    canActivate: [IsForArticleGuard],
  },
  {
    // meine-programme/:program_id/:day_id/video/:slug
    path: 'meine-programme',
    loadChildren: () => import('./video/video.module').then(m => m.VideoModule),
  },
  {
    path: 'newsletter',
    loadChildren: () =>
      import('./newsletter/newsletter.module').then(m => m.NewsletterModule),
  },
  {
    path: 'onboarding',
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: 'programme',
    loadChildren: () =>
      import('./program/program.module').then(m => m.ProgramModule),
  },
  {
    path: 'live-classes',
    component: LiveClassesComponent,
    providers: [provideStates([LiveClassesState])],
  },
  {
    path: 'yogalehrer',
    loadChildren: () =>
      import('./teacher/teacher.module').then(m => m.TeacherModule),
  },
  {
    path: 'yogastile',
    loadChildren: () => import('./style/style.module').then(m => m.StyleModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'video-kategorie',
    loadChildren: () =>
      import('./video-categories/video-categories.module').then(
        m => m.VideoCategoriesModule,
      ),
  },
  {
    path: 'video-kategorien',
    loadChildren: () =>
      import('./video-categories/video-categories.module').then(
        m => m.VideoCategoriesModule,
      ),
  },
  {
    path: 'videos',
    loadChildren: () => import('./video/video.module').then(m => m.VideoModule),
  },
  {
    path: 'produkt',
    canLoad: [AdminGuard],
    loadChildren: () =>
      import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'artikel-tags',
    loadChildren: () => import('./tag/tag.module').then(m => m.TagModule),
  },
  {
    path: 'undefined',
    redirectTo: '/',
  },
  {
    path: ':slug',
    loadChildren: () => import('./page/page.module').then(m => m.PageModule),
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot([...staticRedirects, ...routes], {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
